import { fabric } from "fabric";

jQuery( document ).ready(function($) {

  // newsletter
  $("#eyedress-newsletter-signup-2023").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('#eyedress-newsletter-signup-2023, .newsletter-alert.error').hide()
        $('.newsletter-alert.success').show()
      },
      error: function (err) {
        $('.newsletter-alert.error').show()
      }
    });
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const state = urlParams.get('state')
  if(state) {
    $('.popup.thankyou').addClass('active')
    $('.popup.apple, #apple_music_form, .apple-form-notice.error').hide()
  }

  // apple
  $('.close').click(function(){
    $('.popup.apple').toggleClass('active')
  })

  $('.btn.apple').click(function(e){
    e.preventDefault()
    $('.popup.apple').toggleClass('active')
    // Create a new SMEAppleMusic object with your parameters.
    var sm = new SMEAppleMusic({
      ae: {
      ae: "7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd",
      brand_id: "5091538",
      segment_id: "1747131",
      activities: '{"actions":{"presave":105126},"mailing_list_optins":{"a0S0800000g9EjOEAU":105127}}'
      },
      am: {
        dev_token: "",
        save_mode: "library",
        custom_playlist_name: "",
        new_apple_presave: true,
        resources: {
          playlists: [],
          albums: []
        }
      },
      sf: {
        form: "253497",
        default_mailing_list: ""
      },
      smf: {
        campaign_id: "253497",
        campaign_key: "808a56441998960c7a8674d76dc2e425"
      }
    });
    document.getElementById("apple_music_form").addEventListener("submit", function (e){
      e.preventDefault();
      // Grab the email address value.
      var email = document.getElementById("apple_music_email_address").value;
      // Pass the email address and opt in status
      // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
      sm.doActions(email, false, ["a0S0800000g9EjOEAU"]).then(function (res) {
        // Complete - handle your logic here.
        $('.popup.apple').removeClass('active')
        $('.popup.thankyou').addClass('active')
      }).catch(function (err) {
        // Error - handle your logic here.
        $('.apple-form-notice.error').show()
      });
    });
  })

  $('.toggle-card').click(function(){
    $('.popup.thankyou').addClass('active')
  })

  
  // Thankyou popup
  let txtTo, txtFrom, txtMessage

  $('.back-thankyou').click(function(e){
    $('.popup.thankyou .step-2').hide()
    $('.popup.thankyou .step-1').show()
  })

  $('#card-gen-form').submit(function(e){
    e.preventDefault()
    txtTo = $('#card-gen-form #to').val()
    txtFrom = $('#card-gen-form #from').val()
    txtMessage = $('#card-gen-form #message').val()
    $('.popup.thankyou .step-1').hide()
    $('.popup.thankyou .step-2').show()
    // Canvas setup
    fabric.Object.prototype.set( {
      transparentCorners: false,
      borderColor: '#FFF',
      cornerColor: '#FFF',
      cornerSize: 30,
      cornerStyle: 'circle',
      lockUniScaling: true,
      minScaleLimit: 0.10,
      width: 1200,
      height: 900
    } );
    const canvas = new fabric.Canvas( 'card', {
      backgroundColor: '#000',
      backgroundImage: './dist/img/card-h.png', //Enable this one for prod.
      // backgroundImage: '../../dist/img/card-h.png', //Disable this one for prod.
      enableRetinaScaling: true,
      allowTouchScrolling: false,
      preserveObjectStacking: true,
      selection: false
    } );

    const textTo = new fabric.Text(`To: ${txtTo}`, {
      left: 10,
      top: 20,
      fontFamily: 'medusa',
      fontSize: 40,
      selectable: false
    });

    const textFrom = new fabric.Text(`From: ${txtFrom}`, {
      left: 10,
      top: 100,
      fontFamily: 'medusa',
      fontSize: 40,
      selectable: false
    });

    const textMsg = new fabric.Textbox(`${txtMessage}`, {
      right: 100,
      top: 780,
      width: 1000,
      textAlign: 'right',
      fontFamily: 'medusa',
      fontSize: 40,
      fill: '#C6000F',
      selectable: false,
      textAlign: "right",
    });

    textMsg.width = 1200;
    canvas.add(textTo);
    canvas.add(textFrom);
    canvas.add(textMsg);

    // Download
    $( '.btn.download' ).on( 'click', function ( e ) {
      e.preventDefault();
      const btn = $( this );
      const nonce = btn.attr( 'data-nonce' );
      const img = canvas.toDataURL( { format: 'jpeg', quality: 1 } );
      const a = document.createElement( 'a' );
        a.href = img;
        a.download = 'card.jpeg';
        a.click();
        a.remove();
    } );
    


    resizeCanvases();
    $( window ).on( 'resize', resizeCanvases );
    function resizeCanvases() {
      const parent_width = $( '.canvas-container-overflow' ).parent().width();
      const canvas_width = $( '.canvas-container' ).outerWidth();
      const canvas_height = $( '.canvas-container' ).outerHeight();
      if ( canvas_width > parent_width ) {
        const width_diff = canvas_width - parent_width;
        const scale_diff = width_diff / canvas_width;
        const scale_inverse = 1 - scale_diff;
        $( '.canvas-container' ).css( 'transform', 'scale3d(' + scale_inverse + ', ' + scale_inverse + ', ' + scale_inverse + ')' );
        $( '.canvas-container-overflow' ).css( 'height', canvas_height * scale_inverse );
      } else {
        $( '.canvas-container' ).css( 'transform', 'scale3d(1, 1, 1)' );
        $( '.canvas-container-overflow' ).css( 'height', 'auto' ); 
      }
    }

  })

  $('.popup-close-ty').click(function(e){
    $('.popup.thankyou').removeClass('active')
  })

  $('.btn.share').click(function(e){
    e.preventDefault()
    $('.share-links').toggleClass('active')
  })


});